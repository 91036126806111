import * as Switch from '@radix-ui/react-switch';

const RadioButton = ({ checked, action }: { checked: boolean; action: () => void }) => {
  return (
    <Switch.Root
      className={`ml-3 h-[20px] w-[38px] rounded-full bg-black/90 transition data-[state=checked]:bg-[#00965f]`}
      checked={checked}
      onClick={action}
    >
      <Switch.Thumb
        className={`block h-[14px] w-[14px] translate-x-[3px] rounded-full bg-white shadow transition-transform duration-100 will-change-transform data-[state=checked]:translate-x-[21px]`}
      />
    </Switch.Root>
  );
};

export default RadioButton;
