import React, { useState, useRef } from 'react';

interface ZoomableImageProps {
  src: string;
  alt: string;
}

const ZoomableImage: React.FC<ZoomableImageProps> = ({ src, alt }) => {
  const [isZoomed, setIsZoomed] = useState(false);
  const [isPanning, setIsPanning] = useState(false);
  const [hasPanned, setHasPanned] = useState(false); // New state to track panning
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [offset, setOffset] = useState({ x: 0, y: 0 });
  const imgRef = useRef<HTMLImageElement>(null);
  const start = useRef({ x: 0, y: 0 });

  const panStart = (event: React.PointerEvent<HTMLImageElement>) => {
    if (!isZoomed) return;

    setIsPanning(true);
    setHasPanned(false); // Reset panning state
    start.current = { x: event.clientX, y: event.clientY };

    if (imgRef.current) {
      imgRef.current.setPointerCapture(event.pointerId);
      imgRef.current.style.cursor = 'grabbing';
    }
  };

  const panMove = (event: React.PointerEvent<HTMLImageElement>) => {
    if (!isZoomed || !isPanning) return;

    const deltaX = event.clientX - start.current.x;
    const deltaY = event.clientY - start.current.y;

    if (Math.abs(deltaX) > 2 || Math.abs(deltaY) > 2) {
      setHasPanned(true); // User has moved the image
    }

    setOffset(prevOffset => ({
      x: prevOffset.x + deltaX / 2,
      y: prevOffset.y + deltaY / 2,
    }));

    start.current = { x: event.clientX, y: event.clientY };
  };

  const panEnd = () => {
    if (!isZoomed) return;
    setIsPanning(false);
    if (imgRef.current) imgRef.current.style.cursor = 'grab';
  };

  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (hasPanned) return; // Do NOT toggle zoom if user panned

    const container = e.currentTarget.getBoundingClientRect();
    const clickX = e.clientX - container.left;
    const clickY = e.clientY - container.top;

    let offsetX = 0;
    let offsetY = 0;

    if (clickX > container.width / 2) {
      offsetX = Math.max(container.width / 2 - clickX, -container.width / 4);
    } else {
      offsetX = Math.min(container.width / 2 - clickX, container.width / 4);
    }
    if (clickY > container.height / 2) {
      offsetY = Math.max(container.height / 2 - clickY, -container.height / 4);
    } else {
      offsetY = Math.min(container.height / 2 - clickY, container.height / 4);
    }

    setIsZoomed(prev => !prev);
    setPosition(isZoomed ? { x: 0, y: 0 } : { x: offsetX, y: offsetY });
    setOffset(isZoomed ? { x: 0, y: 0 } : offset); // Keep offset when zooming
  };

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      className={`${isZoomed ? 'touch-none' : ''} relative flex h-full w-full items-start justify-center overflow-hidden`}
      onClick={handleClick}
    >
      <img
        ref={imgRef}
        src={src}
        alt={alt}
        className={`absolute cursor-grab ${isPanning ? '' : 'transition-transform duration-300 ease-in-out'}`}
        style={{
          objectFit: 'contain', // Ser till att bilden anpassas till sin yta utan att croppas
          width: '100%', // Ser till att bilden täcker hela bredden av sin container
          height: '90%', // Ser till att bilden täcker hela höjden av sin container
          transform: isZoomed
            ? `scale(2) translate(${position.x + offset.x}px, ${position.y + offset.y}px)`
            : 'scale(1) translate(0, 0)',
          cursor: isZoomed ? 'grab' : 'pointer',
        }}
        onPointerDown={panStart}
        onPointerMove={panMove}
        onPointerUp={panEnd}
        onPointerCancel={panEnd}
      />
    </div>
  );
};

export default ZoomableImage;
