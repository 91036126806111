/* eslint-disable jsx-a11y/anchor-is-valid */
import { useAtom } from 'jotai';
import { apartmentFilter, apartmentOnTopState, hooveredApartmentId, showAvailableState } from '../states/planner';
import { useState } from 'react';
import Modal from './Modal';
import crossURL from '../assets/cross.svg';
import CarouselApartmentPictures from './CarouselApartmentPictures';
import { Link } from 'react-router-dom';
import { storageUrl } from '../firebase';
import ZoomableImage from './ZoomableImage';

function scrollToTop() {
  const container = document.getElementById('table-container');
  if (container) {
    container.scrollTo(0, 0);
  }
}

const AreaApartment = ({
  apartmentId,
  d,
  toolTipPlace,
  toolTipId,
}: {
  apartmentId: string;
  d: string;
  toolTipPlace: string;
  toolTipId: string;
}) => {
  const [apartments] = useAtom(apartmentFilter);
  const [overApartmentId, setOverApartmentId] = useAtom(hooveredApartmentId);
  const [, setApartmentOnTop] = useAtom(apartmentOnTopState);
  const [showAvailable] = useAtom(showAvailableState);
  const [showModal, setShowModal] = useState(false);

  if (!apartments) {
    return <></>;
  }

  const apartment = apartments.find(a => a.apartmentid === apartmentId);
  if (!apartment) {
    return <></>;
  }

  const setDataToolTip = toolTipId
    ? {
        'data-tooltip-id': toolTipId,
        'data-tooltip-status': apartment.status,
        'data-tooltip-rok': apartment.rok,
        'data-tooltip-price': apartment.price,
        'data-tooltip-charge': apartment.charge,
        'data-tooltip-size': apartment.size,
        'data-tooltip-apartmentid': apartment.apartmentid,
        'data-tooltip-floorplanpicture': apartment.floorplanpicture,
        'data-tooltip-content': '',
        'data-tooltip-place': toolTipPlace,
      }
    : {};

  const pathMouseOver = () => {
    setApartmentOnTop(apartment);
    setOverApartmentId(apartmentId);
    scrollToTop();
  };

  const pathMouseOut = () => {
    setOverApartmentId('');
  };

  const apartmentPictures = apartment.pictures || [];
  const pictures = [apartment.floorplanpicture, ...apartmentPictures];

  const classNames = [
    'location-path',
    apartment.status,
    showAvailable ? 'show' : '',
    overApartmentId == apartmentId ? 'showbold' : '',
  ];

  return (
    <>
      <a
        id={'AreaApartment-' + apartmentId}
        href=""
        onClick={e => {
          e.preventDefault();
          setShowModal(true);
        }}
        className={'relative block'}
      >
        <path
          onMouseOver={() => pathMouseOver()}
          onMouseOut={() => pathMouseOut()}
          id={apartmentId}
          className={classNames.join(' ')}
          d={d}
          {...setDataToolTip}
        />
      </a>

      {showModal ? (
        <Modal>
          <div className="absolute bottom-1 left-1 right-1 top-1 bg-white">
            <button onClick={() => setShowModal(false)} className="absolute right-0 top-0 z-50 m-3">
              <img src={crossURL} alt="close" className="" />
            </button>
            <div className="relative flex h-full w-full flex-col">
              {pictures.length == 1 && (
                <div className="singleapartmentpicture relative flex flex-grow items-center justify-center">
                  <ZoomableImage src={storageUrl(pictures[0])} alt="apartmentpicture" />
                </div>
                // <div className="singleapartmentpicture flex flex-grow items-center justify-center overflow-hidden">
                //   <img
                //     className="max-h-full max-w-full object-contain"
                //     alt="apartmentpicture"
                //     src={storageUrl(pictures[0])}
                //   />
                // </div>
              )}
              {pictures.length > 1 && <CarouselApartmentPictures pictures={pictures} totalPictures={pictures.length} />}

              <div className="flex h-0 items-center justify-center">
                <ul className="flex justify-center gap-10 max-[400px]:gap-2 sm:gap-24">
                  {apartment.interesturl && (
                    <Link to={apartment.interesturl}>
                      <li className="">
                        <button className="apartment-button interest-button h-8 w-44 -translate-y-12 hover:opacity-90 max-[400px]:w-36 max-[400px]:-translate-y-8 sm:h-11 sm:w-52 sm:-translate-y-16 sm:text-sm">
                          INTRESSEANMÄLAN
                        </button>
                      </li>
                    </Link>
                  )}
                  {apartment.sheeturl && (
                    <Link to={apartment.sheeturl} target="_blank">
                      <li className="">
                        <button className="apartment-button info-button h-8 w-44 -translate-y-12 hover:opacity-90 max-[400px]:w-36 max-[400px]:-translate-y-8 sm:h-11 sm:w-52 sm:-translate-y-16 sm:text-sm">
                          BOFAKTABLAD
                        </button>
                      </li>
                    </Link>
                  )}
                </ul>
              </div>
            </div>
          </div>
        </Modal>
      ) : null}
    </>
  );
};

export default AreaApartment;
