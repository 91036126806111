import { useContext, useEffect } from 'react';
import compassUrl from '../assets/compass.svg';
import { CarouselContext } from 'pure-react-carousel';
import { View } from '../CommonTypes';
import { storageUrl } from '../firebase';
import { currentSlideAtom, overviewModalOpenAtom } from '../states/planner';
import { useAtom } from 'jotai';
import { useCameraSVG } from '../hooks/useCameraSVG';
import CompassCameraButton from './CompassCameraButton';
import AreaOverviewApartment from './AreaOverviewApartment';

const CompassWithSVG = ({
  siteViews,
  overviewPicture,
  overviewPictureSVG,
}: {
  siteViews: View[];
  overviewPicture: string;
  overviewPictureSVG: string;
}) => {
  // overviewPictureSVG = '/svg/camerasvgtest2.svg';
  const [showViewSelector, setShowViewSelector] = useAtom(overviewModalOpenAtom);
  const [currentSlide, setCurrentSlide] = useAtom(currentSlideAtom);
  const [viewBox, svgPaths] = useCameraSVG(overviewPictureSVG);
  const carouselContext = useContext(CarouselContext);

  useEffect(() => {
    function onChange() {
      setCurrentSlide(carouselContext.state.currentSlide);
    }
    carouselContext.subscribe(onChange);
    return () => carouselContext.unsubscribe(onChange);
  }, [carouselContext]);

  if (!viewBox) return <></>;

  const viewNames = siteViews.map(view => view.viewname);
  const directions = siteViews.map(view => view.compass);

  const translateCompass = (compass: string) => {
    let degrees = 0;

    switch (compass) {
      case 'N':
        degrees = 180;
        break;
      case 'NNE':
        degrees = 157.5;
        break;
      case 'NE':
        degrees = 135;
        break;
      case 'ENE':
        degrees = 112.5;
        break;
      case 'E':
        degrees = 90;
        break;
      case 'ESE':
        degrees = 67.5;
        break;
      case 'SE':
        degrees = 45;
        break;
      case 'SSE':
        degrees = 22.5;
        break;
      case 'S':
        degrees = 0;
        break;
      case 'SSW':
        degrees = 337.5;
        break;
      case 'SW':
        degrees = 315;
        break;
      case 'WSW':
        degrees = 292.5;
        break;
      case 'W':
        degrees = 270;
        break;
      case 'WNW':
        degrees = 247.5;
        break;
      case 'NW':
        degrees = 225;
        break;
      case 'NNW':
        degrees = 202.5;
        break;
      default:
        degrees = 180;
        break;
    }

    return `rotate(${degrees}deg)`;
  };

  return (
    <>
      <div
        className="absolute left-0 top-0 h-full w-full cursor-default"
        role="button"
        tabIndex={0}
        onKeyDown={e => e.key === 'Enter' && setShowViewSelector(false)}
        style={{ visibility: showViewSelector ? 'visible' : 'hidden' }}
        onClick={() => setShowViewSelector(false)}
      ></div>
      <div
        className={`absolute left-[15%] top-[10%] h-[70%] w-[70%] cursor-default opacity-90 min-[420px]:top-[15%] sm:left-[28%] sm:top-[43%] sm:h-[44%] sm:w-[44%] ${showViewSelector ? 'visible scale-100 opacity-100' : 'invisible scale-90 opacity-0'}`}
      >
        <div className="relative inline-block">
          <img alt="ViewImage" src={storageUrl(overviewPicture)} className={'block h-auto max-w-full rounded-md'} />

          <svg className="absolute left-0 top-0" viewBox={`${viewBox}`} preserveAspectRatio="none">
            {svgPaths.map(l => {
              if (l.type == 'view') {
                return (
                  <CompassCameraButton
                    key={l.ref_id}
                    coordinates={l.coordinates}
                    slide={viewNames.indexOf(l.ref_id)}
                    ref_id={l.ref_id}
                    active={showViewSelector}
                  />
                );
              } else if (l.type == 'apartment') {
                return (
                  <AreaOverviewApartment
                    key={l.ref_id}
                    apartmentIdsStr={l.ref_id}
                    d={l.coordinates}
                    toolTipPlace={l.tooltipplace}
                    toolTipId={'apartment-tooltip'}
                  />
                );
              }
            })}
          </svg>
        </div>
      </div>
      <button
        data-tooltip-id="viewSelector"
        data-tooltip-place="top"
        onClick={() => setShowViewSelector(!showViewSelector)}
        style={{
          transform: `translate(-50%, -50%) ${translateCompass(directions[currentSlide])}`,
        }}
        className="compass z-1 absolute left-1/2 h-[68px] w-[68px] transition-transform duration-500 ease-out hover:h-[72px] hover:w-[72px] sm:h-[82px] sm:w-[82px] sm:hover:h-[86px] sm:hover:w-[86px]"
      >
        <img src={compassUrl} alt={'compass'} />
      </button>
    </>
  );
};

export default CompassWithSVG;
