import { atom } from 'jotai';
import { User, Apartment, Info, View, Site } from '../CommonTypes';

export const siteAtom = atom<Site | null>(null);

export const infoTooltipOpenAtom = atom<boolean>(false);
export const overviewModalOpenAtom = atom<boolean>(false);

export const viewlist = atom<View[]>([]);
export const currentSlideAtom = atom<number>(0);

export const userData = atom<User | null>(null);

export const hooveredApartmentId = atom<string>('');

export const apartmentOnTopState = atom<Apartment | null>(null);

export const apartmentsState = atom<Apartment[]>([]);

export const infoState = atom<Info[]>([]);

export const showAvailableState = atom<boolean>(false);

export const showROKState = atom<boolean[]>([true, true, true, true, true, true]);

export const apartmentFilter = atom(get => {
  const rokFilter = get(showROKState);
  const filter = (a: Apartment) => {
    if (a.rok == 1) return rokFilter[0];
    if (a.rok == 2) return rokFilter[1];
    if (a.rok == 3) return rokFilter[2];
    if (a.rok == 4) return rokFilter[3];
    if (a.rok == 5) return rokFilter[4];
    if (a.rok == 6) return rokFilter[5];
    return true;
  };
  return get(apartmentsState).filter(filter);
});

export const avalableROKInApartments = atom(get => {
  const apartments = get(apartmentsState);
  const availableROK = [0, 0, 0, 0, 0, 0] as number[];
  apartments.forEach(a => {
    if (a.rok == 1) availableROK[0]++;
    if (a.rok == 2) availableROK[1]++;
    if (a.rok == 3) availableROK[2]++;
    if (a.rok == 4) availableROK[3]++;
    if (a.rok == 5) availableROK[4]++;
    if (a.rok == 6) availableROK[5]++;
  });
  return availableROK;
});
