import { QueryStatus, useQuery } from '@tanstack/react-query';

import fetchSite from './fetchSite';
import { Site } from '../CommonTypes';
import { siteAtom } from '../states/planner';
import { useSetAtom } from 'jotai';
import { useEffect } from 'react';

export default function useSite(company: string, site: string) {
  const setSite = useSetAtom(siteAtom); // Function to update atom

  const results = useQuery({
    queryKey: ['site', site, 'company', company],
    queryFn: () => fetchSite(site, company),
  });

  useEffect(() => {
    if (results && results.data !== undefined) {
      setSite(results.data[0]);
    }
  }, [results, setSite]);

  if (results.data === undefined) {
    return [{}, results.status] as [Site, QueryStatus];
  } else {
    // setSite(results.data[0]);
    return [results.data[0], results.status] as [Site, QueryStatus];
  }
}
