import React, { useRef, useEffect } from 'react';
import { Info } from '../CommonTypes';

import { infoTooltipOpenAtom } from '../states/planner';
import { useAtom } from 'jotai';

const AreaInfo = ({
  d,
  title,
  color,
  text,
  iconpath,
  toolTipPlace,
  toolTipId,
}: {
  d: string;
  title: string;
  color: string;
  text: string;
  iconpath: string;
  toolTipPlace: string;
  toolTipId: string;
}) => {
  const pathRef = useRef<SVGPathElement | null>(null);
  const iconRefPath = useRef<SVGPathElement | null>(null);
  const [, setInfoTooltipOpen] = useAtom(infoTooltipOpenAtom);

  const setDataToolTip = toolTipId
    ? {
        'data-tooltip-id': toolTipId,
        'data-tooltip-info': text,
        'data-tooltip-title': title,
        'data-tooltip-place': toolTipPlace,
      }
    : {};

  const isMobile = () => {
    return window.matchMedia('(max-width: 640px)').matches;
  };

  useEffect(() => {
    const handleResize = () => {
      const paths = [pathRef.current, iconRefPath.current];

      paths.forEach(path => {
        if (path) {
          const bbox = path.getBBox();
          const centerX = bbox.x + bbox.width / 2;
          const centerY = bbox.y + bbox.height / 2;

          if (isMobile()) {
            path.setAttribute(
              'transform',
              `translate(${centerX} ${centerY}) scale(1.5) translate(${-centerX} ${-centerY})`
            );
          } else {
            path.setAttribute('transform', 'scale(1)');
          }
        }
      });
    };

    handleResize(); // Call initially to set the correct state

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const imgdata = iconpath;

  return (
    <g
      {...setDataToolTip}
      className="infogroup"
      onClick={() => {
        if (isMobile()) setInfoTooltipOpen(prev => !prev);
      }}
    >
      <path ref={pathRef} className="thecircle" style={{ fill: color, opacity: '0.7' }} d={d}></path>
      <path ref={iconRefPath} style={{ fill: '#FFF' }} className="innerfigure" d={imgdata} />
    </g>
  );
};

export default AreaInfo;
