import { Info, InfoFB } from '../CommonTypes';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../firebase';

const fetchInfos = async (company: string, site: string) => {
  if (process.env.NODE_ENV === 'development') console.log('fetching info', site, company);

  const colRef = collection(db, `${company}/${site}/info`);

  const results = await getDocs(colRef);

  return results.docs.map(doc => {
    const data = doc.data() as Omit<InfoFB, 'id'>;
    return { id: doc.id, title: data.title, text: data.text, coordinates: data.iconpath, color: data.color } as Info;
  });
};

export default fetchInfos;
