/* eslint-disable jsx-a11y/anchor-is-valid */
import { useAtom } from 'jotai';
import { hooveredApartmentId, overviewModalOpenAtom } from '../states/planner';

const AreaOverviewApartment = ({
  apartmentIdsStr,
  d,
}: {
  apartmentIdsStr: string;
  d: string;
  toolTipPlace: string;
  toolTipId: string;
}) => {
  const [overApartmentId] = useAtom(hooveredApartmentId);
  const [showViewSelector] = useAtom(overviewModalOpenAtom);

  if (!showViewSelector) {
    return <></>;
  }
  const apartmentIdsArray = apartmentIdsStr.split(',');

  const classNames = ['overviewapartment-path', apartmentIdsArray.includes(overApartmentId) ? 'show' : ''];

  return (
    <>
      <path id={apartmentIdsStr} className={classNames.join(' ')} d={d} />
    </>
  );
};

export default AreaOverviewApartment;
