import { QueryStatus, useQuery } from '@tanstack/react-query';

import { fetchLocations, fetchLocationsSVG } from './fetchLocations';
import { Location } from '../CommonTypes';

const useLocations = (company: string, site: string, viewId: string) => {
  const results = useQuery({
    queryKey: ['locations', viewId, 'company', company, 'site', site],
    queryFn: () => fetchLocations(viewId, company, site),
  });

  return [results?.data ?? [], results.status] as [Location[], QueryStatus];
};

const useLocationsSVG = (svgUrl: string | undefined) => {
  const results = useQuery({
    queryKey: ['svgUrl', svgUrl],
    queryFn: () => fetchLocationsSVG(svgUrl),
  });

  return [results?.data?.viewBox ?? '', results?.data?.paths ?? [], results.status] as [
    string,
    Location[],
    QueryStatus,
  ];
};

export { useLocations, useLocationsSVG };
