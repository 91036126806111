import { useEffect, useState } from 'react';
import { storageUrl } from '../firebase';
import { siteAtom } from '../states/planner';
import { useAtomValue } from 'jotai';

interface TArg {
  content: string | null;
  activeAnchor: HTMLElement | null;
}

const ToolTipImage = ({ urlin }: { urlin: string }) => {
  const [url, setUrl] = useState('');

  useEffect(() => {
    const img = new Image();
    img.onload = () => {
      setUrl(img.src);
    };
    img.src = storageUrl(urlin);
  }, [urlin]);

  if (!url) return <></>;

  return <img src={storageUrl(url)} alt="floorplanpicture"></img>;
};

const TooltipApartment = ({ activeAnchor }: TArg) => {
  const site = useAtomValue(siteAtom);
  const status = activeAnchor?.getAttribute('data-tooltip-status');
  const rok = activeAnchor?.getAttribute('data-tooltip-rok');
  const price = parseInt(activeAnchor?.getAttribute('data-tooltip-price') || '');
  const charge = parseInt(activeAnchor?.getAttribute('data-tooltip-charge') || '');
  const size = activeAnchor?.getAttribute('data-tooltip-size');
  const apartmentid = activeAnchor?.getAttribute('data-tooltip-apartmentid');
  const floorplanpicture = activeAnchor?.getAttribute('data-tooltip-floorplanpicture') || '';

  const friendlyStatus =
    status === 'available' ? site?.availablelabel : status === 'sold' ? site?.soldlabel : site?.reservedlabel;

  const statusClass =
    status === 'available' ? 'status-available' : status === 'sold' ? 'status-sold' : 'status-pending';

  return (
    <div className="w-60 flex-col pb-2">
      <ul className="flex items-end">
        <li className="flex h-10 w-20 items-center">
          <div
            className={`status-popup-shape ${statusClass} flex items-center justify-center pl-1 pr-5 font-bold text-white`}
          >
            {friendlyStatus}{' '}
          </div>
        </li>
        <li className="flex-grow"></li>
        <li>
          <div className="text-lg font-bold">
            {rok} rok, {size} m<sup>2</sup>
          </div>
          <div className="-mt-1 text-xs">Nr. {apartmentid}</div>
        </li>
      </ul>
      <div className="mt-4 align-top">
        <ToolTipImage urlin={floorplanpicture} />
      </div>
      <ul className="flex items-end pl-4">
        <li className="">
          <ul>
            <li className="mt-2 text-left font-bold">Pris</li>
            <li className="text-xs">{price > 0 ? price.toLocaleString('sv-SE') + ' kr' : '-'}</li>
          </ul>
        </li>
        <li className="flex-grow"></li>
        <li className="">
          <ul>
            <li className="mt-2 text-left font-bold">Avgift</li>
            <li className="text-xs">{charge > 0 ? charge.toLocaleString('sv-SE') + ' kr/mån' : '-'}</li>
          </ul>
        </li>
      </ul>
    </div>
  );
};

export default TooltipApartment;
