import { useAtom } from 'jotai';
import TableAdminApartments from '../components/TableAdminApartments';
import { Navigate, useParams } from 'react-router-dom';
import { userData } from '../states/planner';
import useSite from '../hooks/useSite';

const AdminSite = () => {
  const { company, sitename } = useParams();
  const [user] = useAtom(userData);

  if (!company) {
    throw new Error('no company provided to adminSite');
  }

  if (!sitename) {
    throw new Error('no site provided to adminSite');
  }

  const [site] = useSite(company, sitename);

  if (!user || !site || !site.siteid) {
    return null;
  }

  if (user.id === 'anonymous') {
    console.log('no user');
    return <Navigate to={`/${company}/admin`} replace />;
  }

  if (!site.admins.includes(user.id)) {
    return <div className="m-5">This user do not have access to this page, try Sign out and Login again</div>;
  }
  return (
    <div className="planner-wrapper relative m-5">
      <TableAdminApartments company={company} />
    </div>
  );
};

export default AdminSite;
