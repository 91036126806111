import { useLocations, useLocationsSVG } from '../hooks/useLocations';
import { storageUrl } from '../firebase';
import AreaApartment from './AreaApartment';
import AreaView from './AreaView';
import AreaInfo from './AreaInfo';
import { useInfos } from '../hooks/useInfos';

interface BuildingViewArgs {
  company: string;
  siteid: string;
  viewid: string;
  svgsize: string;
  picture: string;
  svgurl: string;
  compass:
    | 'N'
    | 'NNW'
    | 'NW'
    | 'WNW'
    | 'W'
    | 'WSW'
    | 'SW'
    | 'SSW'
    | 'S'
    | 'SSE'
    | 'SE'
    | 'ESE'
    | 'E'
    | 'ENE'
    | 'NE'
    | 'NNE';
}

const BuildingView = ({ company, siteid, viewid, picture, svgsize, compass, svgurl }: BuildingViewArgs) => {
  const usingSVG = svgurl ? true : false;
  const [locationsFirebase] = useLocations(company, siteid, viewid);
  const [svgViewBox, locationsSvg] = useLocationsSVG(svgurl);
  // const [svgViewBox, locationsSvg] = useLocationsSVG('/svg/example.svg');
  const [infos] = useInfos(company, siteid);

  const locations = usingSVG ? locationsSvg : locationsFirebase;
  const viewBox = usingSVG ? svgViewBox : `0 0 ${svgsize}`;

  if (!viewBox) return <></>;
  return (
    <div className="relative inline-block">
      <img alt="ViewImage" src={storageUrl(picture)} className={compass + ' block h-auto max-w-full'} />
      <svg className="absolute left-0 top-0" viewBox={`${viewBox}`} preserveAspectRatio="none">
        {locations.map(l => {
          if (l.type === 'apartment') {
            return (
              <AreaApartment
                key={l.id}
                apartmentId={l.ref_id}
                d={l.coordinates}
                toolTipPlace={l.tooltipplace}
                toolTipId={'apartment-tooltip'}
              />
            );
          } else if (l.type === 'view') {
            return (
              <AreaView
                company={company}
                siteid={siteid}
                key={l.id}
                id={l.ref_id}
                d={l.coordinates}
                toolTipPlace={l.tooltipplace}
                toolTipId={'view-tooltip'}
              />
            );
          } else if (l.type === 'info') {
            const info = usingSVG ? locations.find(i => i.id === l.ref_id) : infos.find(i => i.id === l.ref_id);
            if (!info) return undefined;

            return (
              <AreaInfo
                title={info.title}
                color={info.color}
                text={info.text}
                iconpath={info.coordinates}
                key={l.id}
                d={l.coordinates}
                toolTipPlace={l.tooltipplace}
                toolTipId={'info-tooltip'}
              />
            );
          }
        })}
      </svg>
    </div>
  );
};

export default BuildingView;
