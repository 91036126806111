import { useState } from 'react';
import { Apartment } from '../CommonTypes';

import {
  SortingState,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';

import TableApartmentsRow from './TableApartmentsRow';
import { siteAtom } from '../states/planner';
import { useAtomValue } from 'jotai';

const TableApartments = ({ apartments }: { apartments: Apartment[] }) => {
  const site = useAtomValue(siteAtom);

  const [sorting, setSorting] = useState<SortingState>([
    {
      id: 'rok', // Must be equal to the accessorKey of the coulmn you want sorted by default
      desc: false,
    },
  ]);

  const columnHelper = createColumnHelper<Apartment>();

  const getHeaderName = (column: string) => {
    switch (column) {
      case 'status':
        return site?.tabletitles.status || 'Status';
      case 'apartmentid':
        return site?.tabletitles.apartmentid || 'Nr.';
      case 'size':
        return site?.tabletitles.size || 'Boarea';
      case 'rok':
        return site?.tabletitles.rok || 'Rum';
      case 'floor':
        return site?.tabletitles.floor || 'Våning';
      case 'price':
        return site?.tabletitles.price || 'Pris';
      case 'charge':
        return site?.tabletitles.charge || 'Avgift';
      default:
        return '';
    }
  };

  const columns = [
    columnHelper.accessor('status', {
      header: info => getHeaderName(info.column.id),
    }),
    columnHelper.accessor('rok', {
      header: info => getHeaderName(info.column.id),
    }),
    columnHelper.accessor('price', {
      header: info => getHeaderName(info.column.id),
    }),
    columnHelper.accessor('charge', {
      header: info => getHeaderName(info.column.id),
    }),
    columnHelper.accessor('size', {
      header: info => getHeaderName(info.column.id),
    }),
    columnHelper.accessor('floor', {
      header: info => getHeaderName(info.column.id),
    }),
    columnHelper.accessor('apartmentid', {
      header: info => getHeaderName(info.column.id),
    }),
    columnHelper.accessor('mainview', {
      header: info => getHeaderName(info.column.id),
    }),
  ];

  const table = useReactTable({
    data: apartments,
    columns: columns,
    state: {
      sorting: sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  const hideWhenMobile = (columnName: string) => {
    if (columnName === 'apartmentid') {
      return '';
      // return 'hidewhenmobile';
    } else if (columnName === 'floor') {
      return '';
      // return 'hidewhenminimobile';
    } else if (columnName === 'mainview') {
      return 'alwayshide';
    }
    return '';
  };

  return (
    <div className="flex w-full select-none justify-center">
      <div
        className="tableapartments w-full overflow-x-auto border-2 border-gray-100 p-2 pl-2 shadow-md md:max-h-56 md:overflow-auto"
        id="table-container"
      >
        <table className="reacttable w-full">
          <thead className="font-style-one text-sm">
            {table.getHeaderGroups().map(headerGroup => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map(header => (
                  <th
                    key={header.id}
                    {...{
                      className: `text-left pr-3 whitespace-nowrap ${hideWhenMobile(header.id)}`,
                    }}
                  >
                    {header.isPlaceholder ? null : (
                      <div
                        {...{
                          className: header.column.getCanSort()
                            ? `cursor-pointer select-none ${['status'].find(s => s == header.id) ? 'text-left' : 'text-right'}`
                            : '',
                          onClick: header.column.getToggleSortingHandler(),
                        }}
                      >
                        {{
                          asc: (
                            <svg viewBox="0 0 512 512" className="inline-block h-4 w-4 rotate-90">
                              <polygon points="352,128.4 319.7,96 160,256 160,256 160,256 319.7,416 352,383.6 224.7,256 " />
                            </svg>
                          ),
                          desc: (
                            <svg viewBox="0 0 512 512" className="inline-block h-4 w-4 -rotate-90">
                              <polygon points="352,128.4 319.7,96 160,256 160,256 160,256 319.7,416 352,383.6 224.7,256 " />
                            </svg>
                          ),
                        }[header.column.getIsSorted() as string] ?? null}
                        {flexRender(header.column.columnDef.header, header.getContext())}
                      </div>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody className="text-xs">
            <TableApartmentsRow row={table.getRowModel().rows[0]} isTopRow={true} />

            {table.getRowModel().rows.map(row => {
              return <TableApartmentsRow key={row.id} row={row} />;
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TableApartments;
