import { Cell, Row } from '@tanstack/react-table';
import { Apartment } from '../CommonTypes';
import { useAtom } from 'jotai';
import { apartmentOnTopState, currentSlideAtom, hooveredApartmentId } from '../states/planner';
import StatusCell from './StatusCell';

class VirtualRow {
  id: string;
  apartmentid: string;
  cells: Cell<Apartment, unknown>[];
  apartmentOnTop?: Apartment;
  mainview?: number;

  constructor(
    id: string,
    apartmentid: string,
    cells: Cell<Apartment, unknown>[],
    apartmentOnTop?: Apartment,
    mainview?: number
  ) {
    this.id = id;
    this.apartmentid = apartmentid;
    this.cells = cells;
    this.apartmentOnTop = apartmentOnTop;
    this.mainview = mainview;
  }

  getVisibleCells(): Cell<Apartment, unknown>[] {
    return this.cells;
  }

  getCellValue(cell: Cell<Apartment, unknown>) {
    if (this.apartmentOnTop) {
      const key = cell.column.id.toString();
      return this.apartmentOnTop[key as keyof Apartment];
    }

    return cell.getValue();
  }
}

const TableApartmentsRow = ({ row, isTopRow }: { row: Row<Apartment>; isTopRow?: boolean }) => {
  const [overApartmentId, setOverApartmentId] = useAtom(hooveredApartmentId);
  const [apartmentOnTop, setApartmentOnTop] = useAtom(apartmentOnTopState);
  const [, setCurrentSlide] = useAtom(currentSlideAtom);

  if (!row) return null;
  if (!isTopRow && apartmentOnTop?.apartmentid === row.getValue('apartmentid')) return null;

  let vRow: VirtualRow;
  if (isTopRow) {
    if (!apartmentOnTop) return null;
    vRow = new VirtualRow(
      'toprow',
      apartmentOnTop.apartmentid,
      row.getVisibleCells(),
      apartmentOnTop,
      row.getValue('mainview')
    );
  } else {
    vRow = new VirtualRow(
      row.id,
      row.getValue('apartmentid'),
      row.getVisibleCells(),
      undefined,
      row.getValue('mainview')
    );
  }

  const classNames = [
    'tr-hooverable',
    'bg-opacity-30',
    vRow.apartmentid === overApartmentId || isTopRow ? 'bg-[#36a14d]' : '',
  ];

  const isMobile = () => {
    return window.matchMedia('(max-width: 640px)').matches;
  };

  const viewEvents = isTopRow
    ? {}
    : {
        onFocus: () => {
          setApartmentOnTop(null);
          setOverApartmentId(vRow.apartmentid);
        },
        onMouseOver: () => {
          setApartmentOnTop(null);
          setOverApartmentId(vRow.apartmentid);
        },
        onMouseOut: () => {
          if (!isMobile()) {
            setApartmentOnTop(null);
            setOverApartmentId('');
          }
        },
        onBlur: () => {
          setApartmentOnTop(null);
          setOverApartmentId('');
        },
        onClick: () => {
          {
            setApartmentOnTop(null);
            setOverApartmentId(vRow.apartmentid);

            if (vRow.mainview !== undefined) {
              setCurrentSlide(vRow.mainview);
            }
          }
        },
      };

  const hideWhenMobile = (columnName: string) => {
    if (columnName === 'apartmentid') {
      return '';
      // return 'hidewhenmobile';
    } else if (columnName === 'floor') {
      return '';
      // return 'hidewhenminimobile';
    } else if (columnName === 'mainview') {
      return 'alwayshide';
    }
    return '';
  };

  return (
    <tr className={classNames.join(' ')} key={vRow.id} {...viewEvents}>
      {vRow.getVisibleCells().map(cell => {
        const cellId = cell.column.id.toString();
        const value = vRow.getCellValue(cell);

        let elementValue = <></>;

        if (cellId === 'status') {
          elementValue = <StatusCell status={value as string} />;
        } else if (cellId === 'price') {
          elementValue = (
            <span className="whitespace-nowrap">
              {typeof value === 'number' && value > 0 ? value.toLocaleString('sv-SE') + ' kr' : '-'}
            </span>
          );
        } else if (cellId === 'charge') {
          elementValue = (
            <span className="whitespace-nowrap">
              {typeof value === 'number' && value > 0 ? value.toLocaleString('sv-SE') + ' kr/mån' : '-'}
            </span>
          );
        } else if (cellId === 'size') {
          elementValue = <span className="whitespace-nowrap">{(value as string) + ' m²'}</span>;
        } else {
          elementValue = <span className="whitespace-nowrap">{value as string}</span>;
        }

        return (
          <td
            {...{
              className: `pr-3 ${hideWhenMobile(cellId)} ${['status'].find(s => s == cellId) ? 'text-left' : 'text-right'}`,
              // className: cellId === 'apartmentid' || cellId === 'floor' ? 'hidewhenmobile pr-3' : 'pr-3',
            }}
            key={cell.id}
          >
            {elementValue}
          </td>
        );
      })}
    </tr>
  );

  return <></>;
};

export default TableApartmentsRow;
