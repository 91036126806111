import { Camera } from '../CommonTypes';

// Fetch viewBox and paths from an SVG file
const fetchCameraSVG = async (svgUrl: string | undefined): Promise<{ viewBox: string; paths: Camera[] }> => {
  try {
    if (!svgUrl) return { viewBox: '', paths: [] };

    const response = await fetch(svgUrl);
    if (!response.ok) throw new Error('Failed to load SVG');

    const svgText = await response.text();
    const parser = new DOMParser();
    const svgDoc = parser.parseFromString(svgText, 'image/svg+xml');

    // Get the <svg> element and its viewBox attribute
    const svgElement = svgDoc.querySelector('svg');
    const viewBox = svgElement?.getAttribute('viewBox') || '';

    // Extract paths as Location[]
    const paths: Camera[] = Array.from(svgDoc.querySelectorAll('path, polygon, circle')).map(element => {
      const isPolygon = element.tagName.toLowerCase() === 'polygon';
      const isCircle = element.tagName.toLowerCase() === 'circle';

      const convertCircleToPath = (cx: string, cy: string, r: string): string =>
        (parseFloat(r) || 0) === 0
          ? ''
          : `M ${+cx - +r},${+cy} A ${+r},${+r} 0 1,0 ${+cx + +r},${+cy} A ${+r},${+r} 0 1,0 ${+cx - +r},${+cy} Z`;

      let coordinates = '';
      if (isPolygon) {
        coordinates = `M ${element.getAttribute('points') || ''} z`;
      } else if (isCircle) {
        coordinates = convertCircleToPath(
          element.getAttribute('cx') || '',
          element.getAttribute('cy') || '',
          element.getAttribute('r') || ''
        );
      } else {
        coordinates = element.getAttribute('d') || '';
      }

      return {
        id: element.getAttribute('id') || '',
        ref_id: element.getAttribute('data-ref_id') || '',
        type: element.getAttribute('data-type') || 'view',
        coordinates,
      } as Camera;
    });
    return { viewBox, paths };
  } catch (error) {
    console.error('Error loading SVG:', error);
    return { viewBox: '', paths: [] };
  }
};

export { fetchCameraSVG };
