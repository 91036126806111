/* eslint-disable jsx-a11y/anchor-is-valid */
import { useAtom } from 'jotai';
import { currentSlideAtom } from '../states/planner';

const CompassCameraButton = ({
  coordinates,
  slide,
  ref_id,
  active,
}: {
  coordinates: string;
  slide: number;
  ref_id: string;
  active: boolean;
}) => {
  const [currentSlide, setCurrentSlide] = useAtom(currentSlideAtom);

  if (slide < 0) return <div></div>;

  const changeSlide = () => {
    if (slide !== currentSlide) {
      setCurrentSlide(slide);
    }
  };

  const currentSlideClass = slide === currentSlide ? 'current' : '';
  return (
    <a
      id={'camera-' + ref_id}
      key={'camera-' + ref_id}
      href=""
      onClick={e => {
        e.preventDefault();
        changeSlide();
      }}
      className={'relative block'}
    >
      <path
        d={coordinates}
        className={`camera-path scale-150 sm:scale-100 sm:hover:scale-110 ${currentSlideClass} ${active ? 'jello-horizontal' : ''}`}
      ></path>
    </a>
  );
};

export default CompassCameraButton;
