import { QueryStatus, useQuery } from '@tanstack/react-query';

import { fetchCameraSVG } from './fetchCameraSVG';
import { Location } from '../CommonTypes';

const useCameraSVG = (svgUrl: string | undefined) => {
  const results = useQuery({
    queryKey: ['svgUrl', svgUrl],
    queryFn: () => fetchCameraSVG(svgUrl),
  });

  return [results?.data?.viewBox ?? '', results?.data?.paths ?? [], results.status] as [
    string,
    Location[],
    QueryStatus,
  ];
};

export { useCameraSVG };
