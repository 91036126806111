interface TArg {
  content: string | null;
  activeAnchor: HTMLElement | null;
}

const TooltipInfo = ({ activeAnchor }: TArg) => {
  const info = activeAnchor?.getAttribute('data-tooltip-info') || '';
  const title = activeAnchor?.getAttribute('data-tooltip-title') || '';

  return (
    <div className="w-64 sm:w-64">
      <div className="pb-1 pl-2 pr-2 pt-1 text-left text-xs font-bold max-[400px]:text-[10px] sm:text-base">
        {title}
      </div>
      {info && (
        <div className="pb-2 pl-2 pr-2 text-left text-xs leading-tight max-[400px]:text-[10px] sm:text-xs">{info}</div>
      )}
    </div>
  );
};

export default TooltipInfo;
